@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-VariableFont_wght.ttf") format("opentype");
}

@font-face {
  font-family: Montserrat-black;
  src: url("../fonts/Montserrat-Black.ttf") format("opentype");
}

html,
html body,
body {
  font-family: "Montserrat", serif;
  font-size: 15px;
  color: #333;
}

#header {
  background: #fff;
  -webkit-box-shadow: 0 3px 5px 0 #d9d9d9;
  -moz-box-shadow: 0 3px 5px 0 #d9d9d9;
  box-shadow: 0 3px 5px 0 #d9d9d9;
}
#footer {
  border-top: #ccc 1px solid;
  padding-top: 50px;
}
#progressbar {
  display: none;
}
.disclaimer {
  font-size: 13px;
  line-height: 1.2em;
}
.copyright {
  font-size: 13px;
  line-height: 1.2em;
  font-weight: bold;
}

.copyright a {
  font-weight: normal;
}
.logo {
  margin-top: 16px;
}
.header-phone {
  text-align: right;
  margin-top: 16px;
}
.header-phone .calltoday {
  font-size: 24px;
}
.header-phone .phonenumber {
  font-size: 24px;
  font-weight: bold;
}
.phonenumber a {
  color: #000;
  text-decoration: none;
}

.subpages {
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

#progressbar {
  margin-top: 20px;
}

#sectionProgress {
  font-weight: bold;
  height: 22px;
}

.question {
  margin: 25px 0px;
}

.question p {
  font-weight: 500;
  font-size: 30px;
  color: #292929;
  line-height: 1.25em;
  margin-bottom: 15px;
}

.btn-350 {
  max-width: 350px;
  text-align: center;
  margin: 5px auto;
}

.question small {
  font-size: 0.85em;
  line-height: 15px;
}

.buttons {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0px;
  padding: 0px;
  justify-content: center;
}

.buttons button {
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  width: 220px;
  min-height: 190px;
  transition: transform 0.3s ease-in-out;
  outline: none;
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 15%);
  border: 1px solid #8ac228;
}

.buttons button:hover {
  box-shadow: 2px 0px 25px 0px rgba(173, 208, 54, 0.5) inset;
  -webkit-box-shadow: 2px 0px 25px 0px rgba(173, 208, 54, 0.5) inset;
  -moz-box-shadow: 2px 0px 25px 0px rgba(173, 208, 54, 0.5) inset;
  transition: transform 0.3s ease-in-out;
}

.buttons button.active {
  border: 1px solid #f08c20;
  box-shadow: 2px 0px 25px 0px rgba(240, 140, 32, 0.5) inset;
  -webkit-box-shadow: 2px 0px 25px 0px rgba(240, 140, 32, 0.5) inset;
  -moz-box-shadow: 2px 0px 25px 0px rgba(240, 140, 32, 0.5) inset;
  transition: transform 0.3s ease-in-out;
}

.buttons button span {
  display: block;
}

.buttons button span.icon {
  margin: 20px auto 0;
  height: 70px;
  width: 70px;
  background-size: cover !important;
}

.buttons button span.text {
  height: 55px;
  margin: 5px;
  line-height: 22px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #142438;
}

.behind-your-payments {
  margin: 0px auto;
  padding: 0px;
  text-align: center;
  max-width: 350px;
}

.behind-your-payments li {
  list-style: none;
  margin: 8px 0px;
  border: #174896 1px solid;
  border-radius: 5px;
  color: #174896;
  font-weight: bold;
  padding: 8px;
  cursor: pointer;
}

.behind-your-payments li.active {
  background-color: #174896;
  color: #fff;
}

.behind-your-payments li.active:hover {
  background-color: #3b68af;
  color: #fff;
}

.behind-your-payments li:hover {
  border: #006aff 1px solid;
  color: #006aff;
  background-color: #e0edff;
}

.price-value {
  font-size: 1.5em;
}

.range-min {
  float: left;
  font-size: 18px;
}
.range-max {
  float: right;
  font-size: 18px;
}

.slider.slider-horizontal {
  width: 100% !important;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider-handle {
  background-color: #05366d !important;
  background-image: -webkit-linear-gradient(
    top,
    #0969c9 0%,
    #05366d 100%
  ) !important;
  background-image: -o-linear-gradient(
    top,
    #0969c9 0%,
    #05366d 100%
  ) !important;
  background-image: linear-gradient(
    to bottom,
    #05366d 0%,
    #05366d 100%
  ) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#05366d', endColorstr='#05366d', GradientType=0) !important;
  filter: none !important;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
  background-color: #05366d !important;
  background-image: linear-gradient(
    to bottom,
    #05366d 0,
    #a6d0ff 100%
  ) !important;
  cursor: pointer !important;
}

.slider::-moz-range-thumb {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
  background-color: #05366d !important;
  background-image: linear-gradient(
    to bottom,
    #05366d 0,
    #a6d0ff 100%
  ) !important;
  cursor: pointer !important;
}

.slider-handle {
  width: 30px !important;
  height: 30px !important;
  top: -5px !important;
  border: #fff 3px solid !important;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75) !important;
}
.slider-selection {
  background: #05366d !important;
}
.slider-track-high {
  background: #a6d0ff !important;
}

.section-hide {
  display: none;
}
.section-show {
  display: block;
}

.parsley-errors-list {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.parsley-errors-list li {
  color: #c00;
  font-weight: bold;
  font-size: 14px;
}

/* modal styling for pivacy policy and terms of use */
.contentModalText .content {
  text-align: left;
  background-color: #ffff;
  padding: 16px;
}
.contentModalText h1 {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #000;
}
.contentModalText h2 {
  font-size: 1.53125rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2em;
  color: #000;
}
.contentModalText h3 {
  font-size: 1.3125rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2em;
  color: #000;
}
.contentModalText h4 {
  font-size: 1.09375rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2em;
  color: #000;
}
.contentModalText p {
  font-size: 14px;
  line-height: 1.5em;
  color: #495057;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 500;
}
.contentModalText .header {
  padding: 20px 20px 20px;
  background: #0a5ca9;
  color: #ffffff;
  width: 100%;
}
.contentModalText .header h3 {
  text-transform: uppercase;
  font-size: 22px;
}

.contentModalText table,
th,
td {
  border: 1px solid black !important;
  border-collapse: collapse !important;
  font-size: 14px !important;
  text-align: left;
  padding: 5px;
  color: #495057;
  line-height: 1.4em;
  margin-bottom: 10px;
}

.contentModalText .content th {
  color: #495057;
  font-weight: 700;
}
.contentModalText .content ul li {
  list-style-type: disc !important;
}
.contentModalText .content ol li {
  list-style-type: decimal !important;
}
.contentModalText .content ul {
  margin-left: 40px !important;
  font-size: 14px !important;
  line-height: 1.5em !important;
  color: #495057 !important;
  margin-bottom: 10px !important;
}
.contentModalText .content ol {
  margin-left: 40px !important;
  font-size: 14px !important;
  line-height: 1.5em !important;
  color: #495057 !important;
  margin-bottom: 10px !important;
}

@media (max-width: 650px) {
  .question p {
    font-size: 17px;
  }

  .buttons {
    overflow-wrap: break-word;
    flex-wrap: wrap;
  }

  .buttons button span.icon {
    float: left;
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-top: 1px;
    height: 40px;
    width: 40px;
  }

  .buttons button {
    background-repeat: no-repeat;
    background-position: right 20px top 25px;
    max-width: 450px;
    margin: 0 auto 15px;
    display: block;
    text-align: left;
    background-color: #fff;
    padding: 15px 20px;
    width: 100%;
    min-height: auto;
  }

  .buttons button span.text {
    line-height: 22px;
    float: left;
    font-size: 17px;
    padding-left: 5px;
    font-weight: 400;
    margin-top: 8px;
    letter-spacing: 0.02em;
    height: auto;
  }
  .input-holder {
    margin: 0px 30px;
  }
  .section-question {
    margin: 0px 15px;
  }
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > thead > tr > th {
    white-space: normal !important;
  }
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    padding: 1px;
  }
  .table-responsive {
    border: none;
  }
}

.contentModal {
  position: fixed;
  z-index: 1000;
  width: 800px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  overflow-y: auto;
  border-radius: 4px;
  outline: none;
  margin: 20px;
  max-height: 100vh;
  padding: 0;
  border: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

@media only screen and (max-width: 1000px) {
  .contentModal {
    width: 80%;
    margin: 0;
  }
}

.contentModalHeader {
  background-color: #0069d9;
  color: white;
  padding: 20px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}

.contentModalText {
  padding: 20px;
  color: #212529;
  font-family: "Montserrat", sans-serif;
}

.contentModalOverlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.pointer-cursor {
  cursor: pointer;
}

.contentModalText ul {
  list-style-type: disc;
}

.contentModalText li {
  margin: 5px;
}

.banner_form {
  position: relative;
  max-width: 40%;
  width: 100%;
  float: right;
}

.confirm-form.banner_form {
  max-width: 100%;
}

.confirmation.heading_form {
  line-height: 30px;
  padding: 18px 33px;
}

.confirmation.banner_text {
  padding-left: 0;
  text-align: center;
  max-width: 100%;
  min-height: 2vh;
  margin: 0 auto;
}

.confirmation.banner_text h3 {
  font-size: 2.2em;
  text-align: center;
  margin-top: 3vh;
  text-transform: uppercase;
  margin-bottom: 3vh;
}

.confirmation.banner_text h3 span {
  font-size: 1em;
}

.confirmation ul {
  color: #353535;
  font-size: 20px;
  padding: 0 35px;
  line-height: 30px;
}
.confirmation ul li {
  margin-bottom: 10px;
}
.confirmation .banner_bottom p {
  text-align: center;
  font-size: 18px;
  color: #353535;
  padding: 0 110px;
  margin: 30px 0 90px;
}
.confirmation .banner_bottom p strong {
  color: #003768;
  line-height: 30px;
  font-weight: 700;
}
.confirmation .banner_bottom h3 {
  text-align: center;
  color: #003768;
  font-size: 30px;
  font-style: italic;
  margin-bottom: 20px;
}
.confirmation .banner_bottom img {
  display: block;
  margin: 0 auto;
}

.container.banner_bottom {
  padding-top: 5%;
  padding-bottom: 3%;
}

#buyer_logo {
  width: 60%;
  margin: auto;
}

#buyer_logo img {
  width: 100%;
}

#buyer_unbold_text {
  font-weight: 600;
  font-size: 1.2em;
  text-align: center;
  padding: 1vw;
}

#buyer_bold_text {
  font-size: 1.8em;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

#credit_offer {
  margin: 5% 3% 2% 3%;
}

.aligned-items {
  flex-direction: column;
  align-items: center;
}

#lender-logo {
  margin-bottom: 1.5em;
}

#lender-logo {
  margin-bottom: 1.5em;
}

#lender-logo img {
  max-width: 100%;
  height: auto;
}

.title {
  color: #003768;
  font-size: 1.5em;
  font-weight: 600;
}

.bulletpoints {
  font-size: 1.2em;
  color: rgb(110, 110, 110);
  font-weight: 400;
}

.bulletpoints ul {
  list-style-type: circle;
}

.responsive-img {
  width: 50%;
}

.wrapper {
  padding: 1.5em 0;
}

.congrats-text {
  text-align: center !important;
  font-size: 1.5em !important;
  line-height: 1.5 !important;
  margin-bottom: 1.5em !important;
}

.thanks-text {
  text-align: center !important;
  font-size: 1em !important;
  line-height: 1.5 !important;
  margin-bottom: 1.5em !important;
}

@media (min-width: 768px) {
  #lender-logo img {
    width: 250px;
  }

  .aligned-items {
    flex-direction: row !important;
  }
  .responsive-img {
    max-width: 100%;
    height: auto;
  }
  .congrats-text {
    font-size: 2.3em !important;
  }
  .thanks-text {
    font-size: 1.9em !important;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  #lender-logo img {
    width: 220px;
  }
}
@media (max-width: 575px) {
  #lender-logo img {
    width: 210px;
  }
}

.selected-behind-payment {
  border: #006aff 1px solid;
  color: #006aff;
  background-color: #e0edff;
}
